import React from "react"
import styled from "styled-components"
import NavButton from "../components/Nav/NavButton"
// import { Link } from "gatsby"
import Layout from "../layout"

const onlineMarketing = () => {
  return (
    <Layout>
      <Contain>
        <ServiceBox>
          <div className="text">
            <div className="textcont">Online Marketing</div>
          </div>
        </ServiceBox>
        <ParaCont>
          <Para>
            What good is a website when nobody is able to find it? As a former
            Online Marketeer and Product Owner, I bring some solid experience in
            discoverability and distribution to the table. I know how to drive
            quality traffic, all across the funnel so visitors also convert when
            we've got them to the website.
          </Para>

          <Para>
            From Paid Search advertising, to Social Media Marketing. I've run
            succesful campaigns on different platforms, for all of my prior
            employers, whether it's Facebook & Instagram, LinkedIn or Twitter.
            I've been there.
          </Para>

          <NavButton />
        </ParaCont>
      </Contain>
    </Layout>
  )
}

const Contain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: #252525;
  min-height: 90vh;
  height: auto;
`

const ServiceBox = styled.div`
  display: flex;
  align-self: center;
  margin: 0 auto;
  margin-bottom: 10vh;

  width: 32vw;
  height: 30vh;
  border-radius: 20px;
  color: #f1f1f1;
  background: #252525;
  font-weight: 500;
  //font-size: 3.5rem;
  font-size: clamp(30px, 6vw, 38px);
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.5s ease;

  .text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: color 0.5s;
    &:hover {
      color: #fff;
    }
  }

  .textcont {
    max-width: 50%;
    line-height: 3.6rem;
    @media only screen and (max-width: 768px) {
      max-width: 75%;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    overflow: hidden;
    height: auto;
    font-size: clamp(22px, 4vw, 28px);
    border-bottom: 1px solid grey;
    padding-right: 0px;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
`

const ParaCont = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Para = styled.div`
  margin-bottom: 5vh;
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #252525;
  max-width: 55%;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
  }
`

// const SLink = styled(Link)`
//   text-decoration: none;
//   color: none;
// `

export default onlineMarketing
